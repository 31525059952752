<script setup lang="ts">
defineProps<{ noLogo?: boolean }>();

const auth = useAuth();
const Logo = computed(() => auth.uiConfig.logoImage);
</script>

<template>
  <v-sheet class="card auth d-flex flex-column pa-6 py-sm-8">
    <div v-if="!$props.noLogo" class="mb-4" style="width: 12rem; margin: 0 auto">
      <img :src="Logo" alt="MyValue" class="w-100 h-100" style="object-fit: contain" />
    </div>
    <slot />
  </v-sheet>
  <slot name="below" />
</template>

<style scoped lang="scss">
.card {
  height: 100%;
  overflow: hidden;
  @media (min-width: $bpSm) {
    height: auto;
    border-radius: 0.75rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  }
}
</style>
